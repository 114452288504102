.b-modal {
  padding: 36px 28px 50px;
  width: 100%;
  
  &__text {
    font: 500 20px/30px var(--font);
    color: var(--primary-table-text-color);
    text-align: center;
    padding: 0 30px;
    margin-bottom: 22px;
  }
  
  &__btnWrapper {
    display: flex;
    justify-content: center;
    
    &.-mt-40 {
      margin-top: 40px;
    }

    &.absolute {
      justify-content: end;
      position: absolute;
      right: 0;
      top: -15px;
    }
  }
}