@import "../../../assets/styles/include-media";

.sideBar {
  font-family: var(--font);
  font-style: normal;
  width: 235px;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  display: none;
  &--active {
    display: flex;
  }

  @include media('<tablet') {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: block;
  }

  &__mobileHeading {
    @include media('<tablet') {
      z-index: 2;
      display: flex;
      align-items: center;
      padding: 12px 20px;
      background-color: #095899;
    }
  }

  &__mobileDropdown {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include media('<tablet') {
      z-index: 1;
      padding-top: 60px;
      padding: 60px 20px 20px;
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(0deg, #07467A 0%, #095899 101.27%);
      flex-direction: column;
      overflow: auto;
      display: none;

      &--active {
        display: flex;
      }
    }
  }

  &__inner {
    position: fixed;
    top: 0;
    left: 0;
    width: 235px;
    height: 100vh;
    padding: 10px 20px 20px;
    background: linear-gradient(0deg, #07467A 0%, #095899 101.27%);
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;

    @include media('<1367px') {
      padding: 10px 20px 10px;
    }

    @include media('<801px') {
      padding: 5px 20px 5px;
    }

    @include media('<tablet') {
      width: 100%;
      height: auto;
      position: static;
      padding: 0;
    }
  }
  &__logo {
    margin: 0 auto;
    display: block;
    // width: 175px;
    height: 35px;

    @include media('<tablet') {
      width: 115px;
      height: auto;
      margin: 0;
    }
  }
  &__avatar {
    width: 80px;
    height: 80px;
    margin: 33px auto 13px;
    background-color: var(--primary-color);
    border-radius: 50%;
    flex-shrink: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/icons/profile_img_default.svg");

    @include media('<1367px') {
      margin: 10px auto 10px;
    }

    @include media('<801px') {
      width: 70px;
      height: 70px;
    }

    @include media('<tablet') {
      width: 60px;
      height: 60px;
      margin-top: 0;
    }
  }
  &__username {
    text-align: center;
    font-weight: 900;
    font-size: 18px;
    line-height: 21px;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 6px;
  }
  &__userrole {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #E5E5E5;
    margin-bottom: 18px;
    text-align: center;

    @include media('<1367px') {
      margin-bottom: 10px;
    }
  }
  &__settings {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    
    @include media('<1367px') {
      margin-bottom: 10px;
    }
  }
  &__settingsButton {
    width: 30px;
    height: 30px;
    margin: 0 10px;
    border-radius: 50%;
    border: 1px solid rgba(244, 244, 244, 0.3);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .2s;

    &:hover,
    &.-active {
      background: #53A0DF;
    }
  }
  &__icon {
    width: 16px;
    height: 16px;
  }
  &__tabsContainer {
    margin: 0 -20px 30px;

    @include media('<1367px') {
      margin: 0 -20px 5px;
    }
  }
  &__tab {
    padding: 14px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .4s;

    @include media('<1367px') {
      padding: 10px 20px;
    }

    @include media('<801px') {
      padding: 7px 20px;
    }

    &:hover {
      background: rgba(#53A0DF, 0.2);
    }

    &.-active {
      background: linear-gradient(0deg, #4490CD 0%, #53A0DF 100%);
    }

    img {
      @include media('<tablet') {
        display: none;
      }
    }
  }
  &__tabText {
    margin-top: 6px;
    font-size: 14px;
    line-height: 1.3;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);

    @include media('<tablet') {
      font-size: 16px;
    }
  }
  &__guidesBtn {
    width: 100%;
    margin: auto 0 0;
    background: #1365A8;
    border-radius: 10px;
    padding: 10px;
    font-weight: bold;
    font-size: 14px;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;

    @include media('<tablet') {
      font-size: 16px;
    }

    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
}

// burger menu style
.navOpener{
  display: none;
  margin-left: auto;
  width:20px;
  height:14px;
  background-color: transparent;
  flex-shrink: 0;
  position: relative;
  overflow:hidden;
  cursor: pointer;
  z-index: 11;

  @include media('<tablet'){
      display: block;
  }

  &:before,
  &:after,
  span{
      background-color: #fff;
      position:absolute;
      top:50%;
      left:0;
      right:0;
      height:2px;
      margin-top:-1px; /* height/2 */
      transition:all 0.2s linear;
  }
  &:before,
  &:after{
      content:'';
      top: 0;
      margin: 0;
  }
  &:after{
    top: auto;
    bottom: 0;
    margin: 0;
  }

  &--active {

    height: 20px;

    &:after,
    &:before {
      transform:rotate(45deg);
      top:50%;
      margin-top: 0;
    }
    &:after {
      transform:rotate(-45deg);
    }

    span {
      display: none;
    }
  }
}

.navOpenerDesktop{
  display: block;
  margin-left: auto;
  width: 20px;
  height: 14px;
  background-color: transparent;
  flex-shrink: 0;
  position: fixed;
  top: 21px;
  right: 5px;
  overflow:hidden;
  cursor: pointer;
  z-index: 11;

   @include media('<tablet'){
      display: none;
   }

  &:before,
  &:after,
  span{
      background-color: #095696;
      position:absolute;
      top:50%;
      left:0;
      right:0;
      height:2px;
      margin-top:-1px; /* height/2 */
      transition:all 0.2s linear;
  }
  &:before,
  &:after{
      content:'';
      top: 0;
      margin: 0;
  }
  &:after{
    top: auto;
    bottom: 0;
    margin: 0;
  }

  &--active {

    height: 20px;
    // position: fixed;
    top: 17px;
    &:after,
    &:before {
      background-color: #095696;
      transform:rotate(45deg);
      top:50%;
      margin-top: 0;
    }
    &:after {
      transform:rotate(-45deg);
    }

    span {
      display: none;
    }
  }
}
