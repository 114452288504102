.auth {
  &__page {
    height: 100vh;
    justify-content: center;
    display: flex;
    align-items: center;
    background-size: cover;
    flex-direction: column;
    background-image: url("../../../assets/images/auth-background.jpg");
  }

  &__input {
    margin-bottom: 15px;

    &.-forgotpass {
      margin-bottom: 40px;
    }

    .-authStyle {
      font-size: 16px;
      line-height: 19px;
      padding-bottom: 5px;
    }
  }

  &__title {
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    font-weight: 900;
  }

  &__policyTitle {
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    font-weight: 900;
    margin-bottom: 20px;
  }

  &__policySubTitle {
    line-height: 21px;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
    color: #666C72;
    margin-bottom: 20px;
  }

  &__policySection {
    font-size: 24px;
    line-height: 30px;
    font-weight: 900;
    margin: 15px auto;
  }

  &__policyBlock {
    max-height: 550px;
    overflow: auto;
    margin-bottom: 25px;

    @include media('<1680px') {
      max-height: 450px;
      margin-bottom: 35px;
    }

    @include media('<1367px') {
      max-height: 250px;
    }

    @include media('<300px') {
      max-height: 200px;
    }
  }

  &__textWrapper {
    margin-bottom: 46px;

    &.-forgotpass {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  &__policyTextWrapper {
    margin-bottom: 15px;
  }

  &__forgot {
    display: block;
    text-align: center;
    padding-top: 25px;
  }

  &__text {
    line-height: 30px;
    font-size: 18px;
    font-weight: 400;
    text-align: center;

    &.-forgotpass {
      font-size: 14px;
      line-height: 20px;
      width: 90%;
      margin-top: 17px;
    }
  }

  &__policyText {
    line-height: 21px;
    font-size: 14px;
    font-weight: 400;
    color: #666C72;

    li {
      padding-left: 20px;
    }
  }

  &__form {
    background-color: #fff;
    margin: 0 auto;
    width: 100%;
    max-width: 520px;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 40px 40px 47px;

    &.policy {
      max-width: 840px;
      padding: 30px 60px;

      @include media('<1367px') {
        padding: 20px 60px;
      }

      @include media('<tablet') {
        padding: 20px;
      }
    }
  }

  &__titleLine {
    width: 80px;
    margin: 10px auto 0;
    height: 3px;
    background-color: #095899;
  }
}
