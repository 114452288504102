@import "../../../assets/styles/include-media";

.tableWrapper {
  padding: 34px 30px 40px;
}

.templateTable {
  
  [class^="p-datatable-wrapper"] {

    @include media('<tablet') {
      overflow: initial !important;
    }
  }

  tr {
    @include media('<tablet') {
      display: block;
      background: none !important;
      position: relative;
    }
  }

  td, th {
    @include media('<tablet') {
      display: block;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &:first-child {
      @include media('<tablet') {
        border-bottom: 0 !important;
      }
    }

  }

  td {
    &:last-child {
      @include media('<tablet') {
        margin-top: -62px;
      }
    }
  }
  
}

.patientControl {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  [class^="check-box-container"] {
    @include media('<tablet') {
      position: absolute;
      top: 17px;
      right: 0;
    }
  }
}

.patientDocLink {
  margin-right: 17px;
  display: block;
  width: 19px;
  height: 20px;
  background: url('../../../assets/images/icons/patient_doc_ic.svg');
  border: none;
  cursor: pointer;
}

.patientEditLink {
  margin-right: 17px;
  display: block;
  width: 14px;
  height: 15px;
  background: url('../../../assets/images/icons/patient_edit_ic.svg') center no-repeat;
}

.templateInfo {
  display: flex;
  min-width: 1px;
  width: 100%;

  @include media('<tablet') {
    flex-direction: column;
  }
}

.ava {
  text-transform: uppercase;
  width: 40px;
  height: 40px;
  display: inline-flex;
  flex-shrink: 0;
  background: #2358a8;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  margin-right: 18px;

  @include media('<tablet') {
    order: 1;
    margin: 12px 0 0;
    height: 30px;
    width: 30px;
    font-size: 14px;
  }

  &.avaCareTransitions {
    background: #2358a8;
  }

  &.avaHome {
    background: #8eb9f9;
  }

  &.avaInpatient {
    background: #4089f8;
  }
}

.title {
  display: block;
  color: var(--primary-text-color);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 5px;

  @include media('<tablet') {
    font-size: 14px;
  }
}

.detailsList {
  display: flex;
  align-items: center;
  font-size: 14px;

  @include media('<tablet') {
    font-size: 12px;
  }

  li {
    padding-right: 15px;
    position: relative;
    white-space: nowrap;

    @include media('<tablet') {
      padding-right: 7px;
    }

    &:nth-child(2) {
      @include media('<tablet') {
        display: none;
      }
    }
  }

  li + li {
    padding-left: 15px;

    @include media('<tablet') {
      padding-left: 7px;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 1px;
      height: 12px;
      background: var(--primary-table-text-color);
    }
  }
}

.creator {
  display: none;
  font-size: 12px;
  
  @include media('<tablet') {
    display: block;
  }
}

.button {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  background: #f3f5f8;
  border-radius: 10px;

  @include media('<tablet') {
    width: 30px;
    height: 30px;
    margin-right: 0;
    margin-left: 10px;
  }

  &:after {
    content: '';
    background-color: var(--primary-color);
    display: block;
    width: 20px;
    height: 20px;

    @include media('<tablet') {
      width: 15px;
      height: 15px;
    }
  }

  &__copy {
    &:after {
      -webkit-mask: url(../../../assets/images/icons/copy.svg) no-repeat center;
      mask: url(../../../assets/images/icons/copy.svg) no-repeat center;
    }
  }

  &__edit {
    &:after {
      -webkit-mask: url(../../../assets/images/icons/edit.svg) no-repeat center;
      mask: url(../../../assets/images/icons/edit.svg) no-repeat center;
      width: 18px;
      height: 17px;

      @include media('<tablet') {
        width: 15px;
        height: 15px;
      }
    }
  }

  &__remove {
    &:after {
      -webkit-mask: url(../../../assets/images/icons/remove.svg) no-repeat center;
      mask: url(../../../assets/images/icons/remove.svg) no-repeat center;
    }
  }
}

.buttonPreview {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  background: #fafafa;
  border-radius: 5px;

  &:after {
    content: '';
    background-color: var(--primary-color);
    display: block;
  }

  &__copy {
    &:after {
      -webkit-mask: url(../../../assets/images/icons/copy.svg) no-repeat center;
      mask: url(../../../assets/images/icons/copy.svg) no-repeat center;
      width: 20px;
      height: 20px;
    }
  }

  &__edit {
    &:after {
      -webkit-mask: url(../../../assets/images/icons/edit.svg) no-repeat center;
      mask: url(../../../assets/images/icons/edit.svg) no-repeat center;
      width: 18px;
      height: 17px;
    }
  }

  &__remove {
    &:after {
      -webkit-mask: url(../../../assets/images/icons/remove.svg) no-repeat center;
      mask: url(../../../assets/images/icons/remove.svg) no-repeat center;
      width: 20px;
      height: 20px;
    }
  }
  &:last-child {
    margin-right: 0;
  }
}

.pages {
  display: flex;
  align-items: center;
  width: 100%;
  .page {
    font: 900 14px/16px 'Roboto', sans-serif;
    color: #414040;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #ffffff;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 14px rgba(0, 0, 0, 0.3);
    }

    &.currentPage {
      color: #fff;
      background: #1365a8;
      box-shadow: none;
    }
  }

  .prev_next_Box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #ffffff;
    border-radius: 15px;
    cursor: pointer;
    &:hover {
      box-shadow: 0 0 14px rgba(0, 0, 0, 0.3);
    }
  }
  .prevIcon {
    width: 10px;
    height: 20px;
    background: url(' ../../../../../assets/images/icons/pagination_prev_ic.svg') center no-repeat;
  }
  .nextIcon {
    width: 10px;
    height: 20px;
    background: url(' ../../../../../assets/images/icons/pagination_next_ic.svg') center no-repeat;
  }

  .first_last {
    font: 900 14px/16px 'Roboto', sans-serif;
    color: #53a0df;
    text-decoration-line: underline;
    text-transform: uppercase;
    margin-left: 20px;
    background: transparent;
    cursor: pointer;
  }

  .paginatorCurrent {
    margin-left: 0;
    margin-right: auto;
    font: 400 14px/16px 'Roboto', sans-serif;
    color: #414040;
  }
}
