.check-box-container {
  display: flex;
  
  label {
    font: 600 14px/1.2 var(--secondaryFont);
    font-size: 14px;
    color: #515151;
    margin-left: 14px;
  }
}

input[type=checkbox] {
  position: relative;
  cursor: pointer;
}
input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -1px;
  left: -2px;
  background-color: #fff;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  box-sizing: border-box;
}
input[type=checkbox]:checked:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -1px;
  left: -2px;
  border-radius: 5px;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 1px;
  left: 5px;
}
