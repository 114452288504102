:root {
  --font: 'Roboto', sans-serif;
  --secondaryFont: 'Nunito Sans', sans-serif;
  --primary-font-size: 14px;
  
  --primary-color: #53A0DF;
  --primary-darkest-color: #1365A8;
  --primary-darkest-hover-color: #134d8e;
  --secondary-color: gold;
  
  --primary-text-color: #414040;
  --primary-table-text-color: #666C72;
  --title-color: #095899;
}
