.contentWrapper {
  padding: 0 0 20px !important;
}

.topWrapper {
  padding: 7px 20px 4px;
  border-bottom: 1px solid #E2EAF6;

  &__title {
    font: 900 20px/50px var(--font);
    color: #414040;
  }
}

.formSettings {
  padding: 30px 20px 0;
  max-width: 686px;
}
.profileImg {
  position: relative;
  width: 94px;
  height: 94px;
  margin-bottom: 30px;

  &__imgWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 94px;
    height: 94px;
    border-radius: 50%;
    overflow: hidden;
    background: var(--primary-color);
  }
  &__ic{
    display: block;
  }
  &__img {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    overflow: hidden;
  }
  &__edit {
    position: absolute;
    right: -5px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border: 1px solid #E5E5E5;
    border-radius: 50%;
    background: url("../../../assets/images/icons/draw.svg") #fff center no-repeat;
    cursor: pointer;
    transition: .4s;
    box-sizing: border-box;
    &:hover {
      background-color: #E5E5E5;
    }
    input {
      position: absolute;
      left: -9999px;
    }
  }
}
.passwordLabel {
  font: 700 14px/16px var(--font);
  color: var(--primary-text-color);
  padding-bottom: 8px;
}
.btnWrapper {
  margin-top: 30px;
}
.modalInner {
  margin-left: auto;
  margin-right: auto;
  max-width: 333px;
}
