.b-form {
  
  &__container {
    display: grid;
    grid-template-columns: 3fr 1fr;

    @include media('<widescreen') {
      display: block;
    }

    &LeftBox {
      padding-right: 30px;

      @include media('<widescreen') {
        padding-right: 0;
      }
    }

    &RightBox {
      padding-left: 30px;
      position: relative;

      &:before {
        position:absolute;
        top: 33px;
        left: 0;
        height: 92%;
        border-left: 1px solid #E2EAF6;
        content: "";
      }

      @include media('<widescreen') {
        padding-left: 0;
        &:before {
          border-left: 0;
        }
      }
    }
  }

  &__subTitle {
    font: 900 16px/19px var(--font);
    color: var(--title-color);
    margin-top: 34px;
    margin-bottom: 23px;
  }
  
  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0 21px;

    @include media('<widescreen') {
      grid-template-columns:repeat(2, 1fr);
    }

    &.hccFour {
      @include media('<widescreen') {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      @include media('<desktop') {
        grid-template-columns: 1fr;
      }
    }

    @include media('<desktop') {
      grid-template-columns: 1fr;
    }
    
    &.-one_column {
      grid-template-columns: 1fr;
      
      &.hccOne {
        @include media('<widescreen') {
          grid-template-columns: 1fr 1fr 1fr;
        }

        @include media('<desktop') {
          grid-template-columns: 1fr;
        }
      }
    }
    &.-two_column {
      grid-template-columns: 1fr 1fr;

      @include media('<widescreen') {
        grid-template-columns: 1fr;
      }

      &.hccTwo {
        @include media('<widescreen') {
          grid-template-columns: 1fr 1fr;
        }

        @include media('<desktop') {
          grid-template-columns: 1fr;
        }
      }
    }
    &.-three_column {
      grid-template-columns: repeat(3, 1fr);

      @include media('<desktop') {
        grid-template-columns: 1fr;
      }
    }
  }
  
  &__smallRow {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-gap: 0 21px;
  }

  &__bigRow {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 0 21px;

    @include media('<desktop') {
      grid-template-columns: 1fr;
    }
  }
  
  &__formGroup {
    margin-bottom: 16px;    
    .mb-0 {
      margin-bottom: 0;
    }
  }

  &__block {
    padding-bottom: 16px;
  }

  &__datePikerLabel {
    font: 700 14px/16px var(--font);
    color: var(--primary-text-color);
    margin-bottom: 8px;
  }

  &__datePikerInput {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    padding-left: 17px !important;
  }

  &__footerBox {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;

    &.column {
      @include media('<tablet') {
        flex-wrap: wrap;
      }
    }
  }

  &__btnWrapper {
    display: flex;
    align-items: center;
    border-top: 1px solid #E2EAF6;
    padding-top: 30px;

    .b-button {
      min-width: 100px;
      width: auto;
    }
  }

  &__btnWrapperHcc {
    display: flex;
    align-items: center;
    border-top: 1px solid #E2EAF6;
    padding-top: 30px;

    &.row {
      @include media('<tablet') {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}

// toggle
.toggle {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;

  &__toolTipTree {
    width: 10.5px;
    height: 12px;
    position: absolute;
    left: 90px;
    top: 5px;
    -webkit-mask: url(../../../assets/images/icons/tree_icon.svg) no-repeat center;
    mask: url(../../../assets/images/icons/tree_icon.svg) no-repeat center;
    background-color: var(--primary-darkest-color);
  }
  
  &__toolTipTree:hover {
    cursor: auto;
  }

  &-group  {
    flex-shrink: 0;
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 15px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    height: 30px;
    width: 76px;
    
    input[type="checkbox"] {
      display: none;
    }
  
    &-on {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding-left: 14px;
      line-height: 28px;
      background: #53A0DF;
      opacity: 0;
    }
  
    &-off {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding-left: 34px;
      background: #8B8B8B;
      line-height: 28px;
    }
  
    &-handle {
      display: block;
      position: relative;
      margin-left: 0;
      margin: 2px;
      width: 24px;
      height: 24px;
      background-color: white;
      border-radius: 50%;
    }
    input[type="checkbox"]:checked {
      & ~ .toggle-group-handle {
        margin-left: auto;
      }
      & ~ .toggle-group-off {
        opacity: 0;
      }
      & ~ .toggle-group-on {
        opacity: 1;
      }
    }
    
  }
  &-label {
    font-size: 14px;
    font-weight: 500;
    color: #414040;
    margin-left: 12px;
  }
}
