.popupBox {
  position: absolute;
  left: 30px;
  right: 30px;
  // width: 96%;
  // margin: 0px 20px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.15);
  // border: 1px solid red;
  z-index: 1;
}

.headerBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 30px;

  &.subQuestion {
  align-items: flex-start;
  }
}

.headerBoxTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 50px;
}

.backBtn {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.sub {
    padding-top: 25px;
  }
}

.icon {
  transform: rotate(-90deg);
}

.body {
  padding: 10px 30px;
}

.body__box {
  margin-bottom: 5px;
}

.body__title {
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
}

.body__inputTitle {
  flex-grow: 1;
  // height: 40px;
  // border: 1px solid #e5e5e5;
  // padding: 5px;
  // &:focus {
  //   border-color: #53a0df;
  // }
}

.body__inputWeight {
  //width: 20%;
  width: 125px;
  margin-left: 10px;
  // height: 40px;
  // border: 1px solid #e5e5e5;
  // padding: 5px;
  // &:focus {
  //   border-color: #53a0df;
  // }
}

.body__textarea {
  width: 100%;
  height: 100px;
  border: 1px solid #e5e5e5;
}

.body__options {
  display: flex;
  margin-bottom: 5px;
  justify-content: space-between;
}
.body_addOption {
  background: #ffffff;
  & span {
    border-bottom: 1px solid #515151;
  }
}
.buttonBox {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.arrowRight {
  position: absolute;
  padding-top: 10px;
  padding-left: 10px;
}

.sub__title {
  font-weight: 700;
  font-size: 14px;
  line-height: 50px;
}

.infoBox {
  display: flex;
  flex-direction: row;
}

.sub__nameOfQuestion {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #095899;
  position: relative;
  margin-right: 30px;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 10.5px;
    height: 12px;
    margin: 0 10px;
    -webkit-mask: url(../../../../../assets/images/icons/vector_right.svg) no-repeat center;
    mask: url(../../../../../assets/images/icons/vector_right.svg) no-repeat center;
    background-color: black;
  }
}

.sub__nameOfOption {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #53A0DF;
  position: relative;
  margin-right: 30px;
  
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 10.5px;
    height: 12px;
    margin: 0 10px;
    -webkit-mask: url(../../../../../assets/images/icons/vector_right.svg) no-repeat center;
    mask: url(../../../../../assets/images/icons/vector_right.svg) no-repeat center;
    background-color: black;
  }
}

.sub__nameOfSubQuestion {
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  color: #095899;
}