@import "../../../assets/styles/include-media";

.back {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  height: 100vh;
  // overflow-y: scroll;
  right: 0;
  background-color: rgba(0,0,0,0.8);
}

.modal {
  position: relative;
  top: 50%;
  left: 50%;
  z-index: 105;
  transform: translate(-50%, -50%);
  width: 95vw;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;

  @include media('<1367px') {
    top: 44%;
  }
}

.wrapTitle {
  padding: 5px 28px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E2EAF6;
}

.wrapMobileTitle {
  padding-top: 32px;
}

.title {
  font: 900 20px/50px var(--font);
  color: var(--title-color);
}

.backBtn {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon {
  transform: rotate(-90deg);
}
