@import "../../../../../assets/styles/include-media";

.box {
  width: 100%;
  max-width: 1140px;
  background: #ffffff;
  margin-bottom: -30px;
}

.headerTitle {
  font-size: 20px;
  font-weight: 800;
  line-height: 50px;
  color: #515151;
  padding: 5px 0 5px 30px;
  border-bottom: 1px solid #e5e5e5;
  margin: 0 -20px 20px;
}

.categoryBox {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-end;

  @include media('<tablet') {
    flex-direction: column;
    align-items: initial;
  }
}

.selectBox {
  flex-grow: 1;
}

.colorBox {
  min-width: 170px;
}

.formGroupDate {
  min-width: 150px;
}

.formGroupCategory {
  flex-grow: 1;
}


.select {
  width: 100%;
  height: 40px;
  padding: 5px;
  border: 1px solid #e5e5e5;
  & option {
    display: block;
  }
  &:focus {
    border-color: #53a0df;
  }
}

.or {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.createBox {
  width: 100%;
  max-width: 300px;
}


.dateBox {
  // width: 100%;
  max-width: 180px;
}

.date {
  height: 40px;
  border: 1px solid #e5e5e5;
  padding: 5px;
  &:focus {
    border-color: #53a0df;
  }
}

.textBox {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  @include media('<tablet') {
    flex-direction: column;
  }
}

.titleBox {
  flex-grow: 1;
}

.initialBox {
  flex-shrink: 0;
  width: 100px;
  margin-left: 20px;

  @include media('<tablet') {
    margin-left: 0;
    width: 100%;
  }
}

.title {
  font-weight: 800;
  line-height: 20px;
}

.inputTitle {
  width: 100%;
  height: 40px;
  border: 1px solid #e5e5e5;
  padding: 5px;
  &:focus {
    border-color: #53a0df;
  }
}

.saveButton {
  width: 100px;
  height: 40px;
  text-align: center;
  font-weight: 900;
  text-transform: uppercase;
  color: #ffffff;
  background: #53a0df;
  // margin: 20px 30px 40px 30px;
  // padding: 10px 0px;
  cursor: pointer;
}