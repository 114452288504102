.b-survey {
  
  &__heading {
    margin: 0 -20px 20px;
    padding: 0 20px 14px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E2EAF6;

    &Ava {
      background-color: #4089F8;
      width: 40px;
      height: 40px;
      font-size: 16px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      color: white;
      border-radius: 10px;
      margin-right: 28px;

      @include media('<tablet') {
        margin-right: 14px;
      }
    }
    &Title {
      font-size: 20px;
      font-weight: 900;
      color: var(--primary-text-color);
      display: block;
      margin-bottom: 5px;
    }
    ul {
      display: flex;
      align-items: center;
      color: #666C72;

      @include media('<tablet') {
        flex-direction: column;
        align-items: flex-start;
      }
      
      li {
        padding-right: 12px;

        @include media('<tablet') {
          padding-top: 5px;
        }

        & + li {
          padding-left: 12px;
          position: relative;

          @include media('<tablet') {
            padding-left: 0;
          }

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            height: 12px;
            width: 1px;
            background-color: #666C72;

            @include media('<tablet') {
              display: none;
            }
          }
        }
        
      }
    }
  }
}

// b-infoBar
.b-infoBar {
  background: #53A0DF;
  border-radius: 5px;
  padding: 15px 20px;
  color: white;
  margin-bottom: 30px;

  &__list {
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;

    @include media('<tablet') {
      flex-direction: column;
      align-items: flex-start;
    }

    li {
      padding-right: 30px;
      display: flex;
      align-items: center;
      position: relative;

      @include media('<tablet') {
        padding-right: 0;
      }

      & + li {
        padding-left: 30px;

        @include media('<tablet') {
          padding-left: 0;
          padding-top: 10px;
        }

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 16px;
          background-color: white;

          @include media('<tablet') {
            display: none;
          }
        }
      }
    }
  }
  &__ava {
    width: 24px;
    height: 24px;
    object-fit: cover;
    margin-right: 10px;
  }
}

// b-surveyForm
.b-surveyForm {
  margin-bottom: 40px;

  &__categoryBlock {
    margin-bottom: 35px;
    counter-reset: newCounter;

  }
  &__block {
    margin-bottom: 35px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__dateRow {
    display: flex;
    margin-bottom: 33px;

    @include media('<tablet') {
      flex-direction: column;
    }
  }
  &__dateCol {
    &+& {
      margin-left: 40px;

      @include media('<tablet') {
        margin-left: 0;
        margin-top: 20px;
      }
    }
    .react-datepicker-wrapper {
      width: 180px;
      cursor: pointer;

      input {
        cursor: pointer;
      }
    }
  }
  &__tilte {
    font-size: 17px;
    font-weight: 700;
    color: var(--primary-text-color);
    margin: 0 0 20px;
  }
  &__item {
    margin-bottom: 25px;

    &Label {
      font-size: 16px;
      font-weight: 700;
      color: var(--primary-text-color);
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      margin-top: 5px;

      .toggle-group {
        margin-left: 13px;
      }
      .b-surveyform__icon {
        margin-left: 7px;
      }
    }
    &QuestionDescription {
      font-size: 14px;
      line-height: 16px;
      font-weight: bold;
      color: var(--primary-text-color);
    }

    &SubQuestionBlock {
      counter-reset: newCounter;
    }

    &Question {
      display: flex;

      &:before {
        counter-increment: newCounter;
        content: counters(newCounter,".") "."; 
        margin-right: 5px;
      }

      &SubQuestion {
        display: flex;
        margin-left: 15px;
        margin-top: 10px;

        &:before {
          counter-increment: newCounter;
          content: counters(newCounter,".") "."; 
          margin-right: 5px;
        }
      }
    }

    &QuestionPreview {
      display: flex;
    }

    &SubQuestionPreview {
      margin-left: 20px;
      margin-top: 3px;
    }
  }

  &__icon {
    -webkit-mask: url(../../../assets/images/icons/add_user_icon.svg) no-repeat center;
    mask: url(../../../assets/images/icons/add_user_icon.svg) no-repeat center;
    background-color: var(--primary-color);
    min-width: 20px;
    height: 20px;
    margin-left: 7px;
    margin-bottom: 2px;
  }

  &__iconSurvey {
    -webkit-mask: url(../../../assets/images/icons/add_user_icon.svg) no-repeat center;
    mask: url(../../../assets/images/icons/add_user_icon.svg) no-repeat center;
    background-color: var(--primary-color);
    width: 20px;
    height: 20px;
    margin-right: 7px;
    margin-bottom: 2px;
  }

  &__chart {
    border: 1px solid #E2EAF6;
    margin-bottom: 35px;
    display: flex;
    max-width: 1000px;
    min-width: 1px;
    margin-bottom: 20px;
    
    @include media('<widescreen') {
      flex-direction: column;
    }

    &__sideBlock {
      padding: 20px;

      @include media('<widescreen') {
        padding: 15px;
      }
      
      &:last-child {
        border-left: 1px solid #E2EAF6;

        @include media('<widescreen') {
          border-left: 0;
          border-top: 1px solid #E2EAF6;
        }
      }

      &.-chart {
        // width: Min(63%, 540px);
        width: Min(370px, 100%);
        flex-shrink: 0;

        @include media('<widescreen') {
          margin: 0 auto;
          // width: Min(370px, 100%);
        }

      }

      &.-chartDetails {
        flex-grow: 1;
        min-width: 1px;
      }

      .score-container {
        position: absolute;
        top: 22%;
        left: 50%;
        transform: translateX(-50%);
        min-width: 30%;

        @include media('<widescreen') {
          top: 24%;
        }

        @include media('<tablet') {
          top: 30%;
        }

        .score {
          font-weight: 900;
          font-size: 56px;
          line-height: 1.2;
          color: #414040;
          text-align: center;

          @include media('<widescreen') {
            font-size: 48px;
          }
          @include media('<tablet') {
            font-size: 30px;
          }
        }
       
      }

      // .score-containerCreateSurveyPage {
      //   position: absolute;
      //   top: 33%;
      //   left: 45%;
      //   transform: translate(-33%, -45%);
      //   // top: 50%;
      //   // left: 50%;
      //   // transform: translate(-50%, -50%);
      //   min-width: 30%;

      //   @include media('<tablet') {
      //     top: 40%
      //   }
        
      //   .score {
      //     font-weight: 900;
      //     font-size: 48px;
      //     line-height: 48px;
      //     color: #414040;
      //     text-align: center;
      //     @include media('<desktop') {
      //       font-size: 34px;
      //       line-height: 34px;
      //     }
      //     @include media('<tablet') {
      //       font-size: 26px;
      //       line-height: 26px;
      //     }
      //   }
       
      // }

      .score-level-container {
        transform: translateY(-100%);
        
        .score-level {
          font-weight: 900;
          font-size: 17px;
          line-height: 1.2;
          text-align: center;
          color: #414040;
          
          @include media('<tablet') {
            font-size: 14px;
          }
          
        }
      }

      // .score-level-containerCreateSurveyPage {
      //   position: absolute;
      //   // top: 92%;
      //   left: 75%;
      //   transform: translate(-92%, -78%);
      //   min-width: 60%;

      //   .score-level {
      //     font-weight: 900;
      //     font-size: 18px;
      //     line-height: 21px;
      //     text-align: center;
      //     color: #414040;
          
      //     @include media('<tablet') {
      //       font-size: 14px;
      //       line-height: 16px;
      //     }
      //   }
      //   @include media('<400px') {
      //     bottom: -15%;
      //   }
      // }

      // &.-chart {
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      // }
      
    }

    &__chartHolder {
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-top: 56%;
      }
    }
    
    &__chartFrame {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &__factors {
      margin-top: 22px;
      
      .factor {
        border-radius: 20px;
        margin-bottom: 3px;
        cursor: pointer;
        
        &.low {
          background-color: #6FDE47;
        }
        &.medium {
          background: #E5D012;
        }
        &.high {
          background-color: #DB4E6F;
        }

        .factor-text {
          display: block;
          padding: 0 20px;
          color: #FFFFFF;
          font-size: 15px;
          line-height: 22px;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          overflow: hidden;

          @include media('<widescreen') {
            padding: 0 10px;
          }
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }
  
  &__buttons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #E2EAF6;
    padding-top: 20px;
    padding-bottom: 20px;

    &Left {
      .b-button {
        margin-right: 10px;
      }
    }
    &Right {
      .b-button {
        margin-left: 10px;
      }
    }

    .b-button {
      width: auto;
    }

  }
  &__errorText {
    color: red;
    font-size: 12px;
    padding-left: 5px;
    padding-top: 1px;
  }

  &__notes {
    margin-bottom: 10px;
  }
}

// b-customRadioList
.b-customRadioList {
  position: relative;
  display: flex;
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  
  @include media('<tablet') {
    width: 100%;
    overflow: auto;
  }

  &.--vertical {
    flex-direction: column;

    label ~ label {
      border-left: 0;
      border-top: 2px solid var(--primary-color);
    }
  }

  input {
    position: absolute;
    // left: -9999px;
    width: 0px;
    opacity: 0;
  }
  label {
    flex: 1;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #666C72;
    padding: 15px;
    cursor: pointer;
    transition: all .3s;
    position: relative;

    & ~ label {
      border-left: 2px solid var(--primary-color);
    }

    @include media('<tablet') {
      font-size: 14px;
      padding: 10px;
    }
    @include media('<400px') {
      font-size: 12px;
      padding: 10px 5px;
    }
  }

  input:checked + label {
    background-color: var(--primary-color);
    color: white;
    .b-customRadioList__toolTipTree {
      background-color: white;
    }
  }

  &__toolTipTree {
    width: 10.5px;
    height: 12px;
    position: absolute;
    right: 3px;
    top: 5px;
    -webkit-mask: url(../../../assets/images/icons/tree_icon.svg) no-repeat center;
    mask: url(../../../assets/images/icons/tree_icon.svg) no-repeat center;
    background-color: var(--primary-darkest-color);
  }

  label:hover {
    background-color: var(--primary-color);
    color: white;
    .b-customRadioList__toolTipTree {
      background-color: white;
    }
  }
}


// b-customCheckBoxList

.b-customCheckBoxList {
  display: flex;
  padding: 10px;
  position: relative;

  label {
    margin-left: 10px;
    flex: 1;
    font-size: 16px;
    font-weight: 700;
    color: #666C72;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &__toolTipTree {
    width: 10.5px;
    height: 12px;
    position: absolute;
    right: 3px;
    top: 5px;
    -webkit-mask: url(../../../assets/images/icons/tree_icon.svg) no-repeat center;
    mask: url(../../../assets/images/icons/tree_icon.svg) no-repeat center;
    background-color: var(--primary-darkest-color);
  }
  
  &__toolTipTree:hover {
    cursor: auto;
   }
}

// b-relatedQuestionnaires
.b-relatedQuestionnaires {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @include media('<desktop') {
    grid-template-columns: 1fr;
  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__number {
    border-radius: 10px;
    margin-right: 13px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 700;
    color: white;
    flex-shrink: 0;

    @include media('<tablet') {
      width: 40px;
      height: 40px;
      font-size: 20px;
    }

    &.low {
      background-color: #6FDE47;
    }
    &.medium {
      background: #E5D012;
    }
    &.high {
      background-color: #DB4E6F;
    }
  }

  &__title {
    display: block;
    color: var(--primary-text-color);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;

    @include media('<tablet') {
      font-size: 14px;
    }
  }
  ul {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    color: #666C72;

    @include media('<tablet') {
      font-size: 12px;
    }

    li {
      padding-right: 12px;
      position: relative;

      @include media('<tablet') {
        padding-right: 6px;
      }

      & + li {
        padding-left: 12px;

        @include media('<tablet') {
          padding-left: 6px;
        }

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          height: 12px;
          width: 1px;
          background-color: #666C72;
        }
      }

    }

  }
}

// b-shareLink
.b-shareLink {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &__label {
    display: block;
    color: var(--title-color);
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  &__inputRow {
    display: flex;
    align-items: center;
  }
  &__input {
    flex-grow: 1;
    input {
      color: var(--primary-color);
    }
  }
  &__btn {
    border: 1px solid #53A0DF;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
      background-color: var(--primary-color);

      &:after {
        background-color: white;
      }
    }

    &:after {
      content: '';
      background-color: var(--primary-color);
      width: 20px;
      height: 20px;
    }

    &--print {
      &:after {
        -webkit-mask: url(../../../assets/images/icons/print.svg) no-repeat center;
        mask: url(../../../assets/images/icons/print.svg) no-repeat center;
      }
    }
    &--send {
      &:after {
        -webkit-mask: url(../../../assets/images/icons/envelope.svg) no-repeat center;
        mask: url(../../../assets/images/icons/envelope.svg) no-repeat center;
      }
      
    }

  }
}

// b-historyTable
.b-historyTable {
  width: 100%;
  overflow: auto;
  margin-bottom: 35px;

  table {
    width: 100%;
    border-collapse: collapse;
    color: var(--primary-table-text-color);

    td {
      border-bottom: 1px solid #F4F4F4;
      padding: 14px 10px;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  &__icon {

    &:after {
      content: '';
      width: 17px;
      height: 17px;
      display: block;
    }

    &.--created {
      &:after {
        -webkit-mask: url(../../../assets/images/icons/add-plus.svg) no-repeat center;
        mask: url(../../../assets/images/icons/add-plus.svg) no-repeat center;
        background-color: var(--primary-darkest-color);
      }
    }
    &.--updated {
      &:after {
        -webkit-mask: url(../../../assets/images/icons/edit.svg) no-repeat center;
        mask: url(../../../assets/images/icons/edit.svg) no-repeat center;
        background-color: #DB4E6F;
      }
    }
    &.--completed {
      &:after {
        -webkit-mask: url(../../../assets/images/icons/check-circle.svg) no-repeat center;
        mask: url(../../../assets/images/icons/check-circle.svg) no-repeat center;
        background-color: #6FDE47;
      }
    }
    
  }
}

// b-addComment
.b-addComment {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &__label {
    display: block;
    color: var(--primary-text-color);
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  &__inputRow {
    display: flex;
    align-items: center;
  }
  &__input {
    flex-grow: 1;
  }
}

// b-dList
.b-dList {
  color: #666C72;

  dt {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5px;
  }
  dd {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 16px;
  }
}

