
html, body {
	font-family: var(--font);
	font-size: var(--primary-font-size);
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

* {
	margin: 0;
	padding: 0;
	outline: 0;
	box-sizing: border-box;
}

p {
	font-size: 14px;
	line-height: 1.5;
	font-weight: 400;
	color: var(--primary-table-text-color);
	margin: 0 0 20px;
}

a {
	transition: .3s ease-in-out;
	text-decoration: none;
	color: var(--primary-color);
	cursor: pointer;
}

button {
	transition: .3s ease-in-out;
	border: none;
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

li {
	list-style-type: none;
}

.b-link {
	color: var(--primary-color);
	text-decoration: underline;
}
.mb-0 {
	margin-bottom: 0;
}
