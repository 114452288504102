.b-mainContainer {
  display: flex;
  min-height: 100vh;
  
  @include media('<tablet') {
    padding-top: 50px;
  }
  
  &__wrapper {
    width: 100%;
    background: hsl(217, 100%, 96%);
    min-width: 1px;
    // z-index: 1;
    position: relative;
  }
  
  &__wrapperInner {
    padding: 30px;
    width: 100%;
    box-sizing: border-box;

    @include media('<tablet') {
      padding: 10px;
    }
  }
  
  &__contentWrapper {
    background: #FFFFFF;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 20px;

    & + & {
      margin-top: 20px;
    }
  }
  
  &__elementWrapper {
    width: 100%;
    background: #FFFFFF;
    border-radius: 15px;
  }

  &__contentBox {
    padding: 30px 20px;
  }
}