.carousel {
  
  &.carousel-slider {
    //padding-bottom: 30px;
    user-select: none;
    position: relative;
  }
  
  .thumbs-wrapper {
    display: none;
  }
  .carousel-status {
    display: none;
  }
  
  .control-dots {
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    width: auto;
    
    .dot {
      margin: 0 5px;
      width: 8px;
      height: 8px;
      border-radius: 0;
      box-shadow: none;
      background: #F3F5F8;
      transition: background-color .2s;
      cursor: pointer;
      opacity: 1;

      &:hover,
      &.selected {
        background-color: var(--primary-darkest-color);
      }
    }
  }
  
  .control-arrow {
    height: calc(100% - 30px);
  }
  
  .slideWrapper {
    padding-bottom: 30px;
  }
}