.p-datatable {

  &-wrapper {
    overflow: auto;
    width: 100%;
  }

  /* Footer */
  &-footer {
    .patientControlList {
      padding: 15px 5px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #E2EAF6;
    
      @include media('<desktop') {
        padding-left: 0;
        padding-right: 0;
        border: 0;
        flex-wrap: wrap;
      }
      
      &__innerWrapper {
        display: flex;
        flex-wrap: wrap;

        @include media('<tablet') {
          width: 100%;
        }
      }
      .b-button {
        width: auto;
        margin: 5px;

        @include media('<tablet') {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    .footerBtn {
      border-radius: 5px;
      width: 240px;
      height: 40px;
      font-size: 14px;
      font-weight: 900;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding-left: 20px;
      padding-right: 20px;
      cursor: pointer;
      position: relative;
      background-color: var(--primary-color);
      color: #fff;
      margin: 5px;

      @include media('<tablet') {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    
      &:hover {
        background-color: #477fb5;
      }
    }.disabled {
      opacity: 0.5;
    }
  }
  
  &.-paginator {
    position: relative;
    padding-bottom: 30px;

    @include media('<desktop') {
      padding-bottom: 0;
    }
    
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  .p-datatable-thead {
    width: 100%;
    th {
      font: 900 16px/19px var(--font);
      color: var(--primary-text-color);
      text-align: left;
      padding: 13px 9px;
      border-bottom: 1px solid #E2EAF6;
      white-space: nowrap;
    }
    .p-column-title {
      &.-flex {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .p-column-title-inner {
        margin-right: 12px;
        padding-top: 4px;
      }
    }
    
    .p-sortable-column {
      cursor: pointer;
    }
    .p-sortable-column-icon {
      margin-left: 10px;
      display: inline-block;
      width: 10px;
      height: 8px;
      background: url("../../images/icons/sort_table_ic.svg") center no-repeat;
    }
    .filters {
      font-weight: 400;
      display: flex;
      align-items: center;

      @include media('<desktop') {
        flex-direction: column;
        align-items: flex-start;
      }

      [class^="Select"] {
        font-size: 14px;
        min-width: 250px;

        @include media('<desktop') {
          width: 100%;
        }
      }

      .searchForm {
        margin-left: 20px;
        
        @include media('<widescreen') {
          margin-left: 10px;
        }

        @include media('<desktop') {
          margin-left: 0;
          margin-top: 10px;
          width: 100%;
        }
      }
      .categoryList {
         margin-left: 30px;

         @include media('<widescreen') {
          margin-left: 10px;
        }

         @include media('<desktop') {
          margin-left: 0;
          margin-top: 15px;
        }
      }
      .checkAll {
        margin-left: auto;
      }
    }
  }
  .p-datatable-tbody {
    tr {
      &:nth-of-type(even) {
        background: #F2FAFF;
      }
      &:nth-of-type(odd) {
        background: #fff;
      }
    }
    td {
      padding: 16px 9px;
      font: 400 14px/16px var(--font);
      color: var(--primary-table-text-color);
      border-bottom: 1px solid #E2EAF6;
    }
  }

  .date {
    white-space: nowrap;
  }
}

/* Paginator */
.p-paginator {
  display: flex;
  align-items: center;
  width: 100%;

  @include media('<desktop') {
    flex-wrap: wrap;
    position: static;
    justify-content: center;
    // padding-top: 30px;
    position: relative;
  }

  .p-paginator-current {
    margin-left: 0;
    margin-right: auto;
    font: 400 14px/16px var(--font);
    color: var(--primary-text-color);

    @include media('<desktop') {
      order: 1;
      width: 100%;
      text-align: center;
      padding: 15px 50px 0;
    }
  }
  .p-paginator-currentText {
    @include media('<desktop') {
      display: block;
    }
  }
  
  .p-paginator-pages {
    display: flex;
  }
  .p-paginator-first {
    font: 900 14px/16px var(--font);
    color: var(--primary-color);
    text-decoration-line: underline;
    text-transform: uppercase;
    margin-right: 20px;
    background: transparent;
    cursor: pointer;

    @include media('<tablet') {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0;
    }

    &.p-disabled {
      opacity: .45;
      cursor: default;
    }
  }
  .p-paginator-last {
    font: 900 14px/16px var(--font);
    color: var(--primary-color);
    text-decoration-line: underline;
    text-transform: uppercase;
    margin-left: 20px;
    background: transparent;
    cursor: pointer;

    @include media('<tablet') {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0;
    }

    &.p-disabled {
      opacity: .45;
      cursor: default;
    }
  }
  .p-paginator-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 14px rgba(0, 0, 0, 0.3);
    }
    
    .pi-angle-left {
      width: 10px;
      height: 20px;
      background: url("../../../assets/images/icons/pagination_prev_ic.svg") center no-repeat;
    }
  }
  .p-paginator-next {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 14px rgba(0, 0, 0, 0.3);
    }

    .pi-angle-right {
      width: 10px;
      height: 20px;
      background: url("../../../assets/images/icons/pagination_next_ic.svg") center no-repeat;
    }
  }
  .p-paginator-page {
    font: 900 14px/16px var(--font);
    color: var(--primary-text-color);
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    cursor: pointer;
    
    &:hover {
      box-shadow: 0 0 14px rgba(0, 0, 0, 0.3);
    }
    
    &.p-highlight {
      color: #fff;
      background: var(--primary-darkest-color);
      box-shadow: none;
    }
  }
}

.p-datatable-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E2EAF6;
  padding: 0 20px 14px;
  margin: 0 -20px 20px;

  @include media('<tablet') {
    flex-direction: column;
    align-items: inherit;
  }

  &__title {
    font-size: 20px;
    font-weight: 900;
    color: var(--primary-text-color);

    @include media('<tablet') {
      margin-bottom: 20px;
    }
  }

  &__container {
    justify-content: flex-end;
    // flex-grow: 2;
    display: flex;
    flex-wrap: wrap;
    // margin-right: 20px;

    @include media('<desktop') {
      flex-direction: column;
      margin-right: 0px;
    }
  }

  &__containerShare {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    @include media('<desktop') {
      flex-direction: column;
      margin-right: 0px;
    }
  }

  &__col {
    width: 150px;
    margin: 5px;

    @include media('<widescreen') {
      margin: 10px;
    }

    @include media('<desktop') {
      width: 100%;
      margin: 0 0 12px;
    }
  }
  &__colShare {
    width: 100%;
    // margin: 10px;

    // @include media('<widescreen') {
    //   margin: 10px;
    // }

    // @include media('<desktop') {
    //   width: 100%;
    //   margin: 0 0 12px;
    // }
  }
  &__iconsBox {
    display: flex;
    justify-content: flex-end;
    @include media('<tablet') {
      margin-top: -35px;
    }
  }
  &__popupContainer {
    align-self: flex-end;
    margin: 5px 10px 5px 10px;
    position: relative;

    @include media('<widescreen') {
      // margin-bottom: 20px;
    }

    &:hover {
      .p-datatable-filters__popup {
        display: block;
      }
    }
  }

  &__popupContainerInfo {
    margin-right: 8px;
    position: relative;

    &:hover {
      .p-datatable-filters__popupInfo {
        display: block;
      }
    }
  }

  &__popupContainerShare {
    position: relative;

  }
  &__popupFilter {
    -webkit-mask: url(../../../assets/images/icons/filter-icon.svg) no-repeat center;
    mask: url(../../../assets/images/icons/filter-icon.svg) no-repeat center;
    background-color: var(--primary-color);
    width: 20px;
    height: 20px;
    display: block;
    cursor: pointer;

    &.--active {
    background-color: #e5d012;
    }
  }
  &__popupOpener {
    -webkit-mask: url(../../../assets/images/icons/settings.svg) no-repeat center;
    mask: url(../../../assets/images/icons/settings.svg) no-repeat center;
    background-color: var(--primary-color);
    width: 20px;
    height: 20px;
    display: block;
    cursor: pointer;
  }

  &__popupOpenerInfo {
    -webkit-mask: url(../../../assets/images/icons/icon_info.svg) no-repeat center;
    mask: url(../../../assets/images/icons/icon_info.svg) no-repeat center;
    background-color: var(--primary-color);
    width: 20px;
    height: 20px;
    display: block;
    cursor: pointer;
  }

  &__chartPopup {
    -webkit-mask: url(../../../assets/images/icons/chart.svg) no-repeat center;
    mask: url(../../../assets/images/icons/chart.svg) no-repeat center;
    background-color: var(--primary-color);
    width: 20px;
    height: 20px;
    display: block;
    cursor: pointer;
  }

  &__popup {
    position: absolute;
    right: 0;
    top: 100%;
    width: 200px;
    z-index: 10;
    padding-top: 10px;
    display: none;
  }

  &__popupInfo {
    position: absolute;
    left: 0;
    top: 90%;
    width: 250px;
    z-index: 10;
    display: none;

    @include media('<widescreen') {
      left: auto;
      right: 0;
    }
  }

  &__popupShare {
    position: absolute;
    right: 0;
    bottom: 45px;
    z-index: 10;
    display: none;
    &.active {
      display: block;
    }
  }

  &__popupFrame {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background-color: white;
  }
  &__popupHeading {
    font: 600 12px/1.2 var(--secondaryFont);
    text-transform: uppercase;
    color: #515151;
    border-bottom: 1px solid #E2EAF6;
    padding: 12px 20px;
  }
  &__popupList>li {
    font: 400 10px;
    color: #515151;
    border-bottom: 1px solid #E2EAF6;
    padding: 5px 0px;
  }
  &__popupContent {
    padding: 15px 20px;
  }
  &__popupContentShare {
    padding: 10px;
  }
}

// searchForm
.searchForm {
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  height: 40px;
  width: 333px;
  position: relative;

  @include media('<desktop') {
    width: 180px;
  }
  
  &:after {
    content: '';
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-mask: url(../../../assets/images/icons/search.svg) no-repeat center;
    mask: url(../../../assets/images/icons/search.svg) no-repeat center;
    width: 16px;
    height: 16px;
    background-color: #A48AD4;
  }

  input {
    border: 0;
    color: var(--primary-table-text-color);
    font-size: 14px;
    line-height: 1.2;
    padding: 10px 40px 10px 20px;
    width: 100%;
  }
}

// categoryList
.categoryList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #414040;
  font-size: 14px;
  font-weight: 700;
  min-width: 1px;

  @include media('<widescreen') {
    font-size: 13px;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
    cursor: pointer;
    opacity: 0.5;

    @include media('<widescreen') {
      margin-right: 10px;
    }

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      margin-right: 10px;
      margin-top: 3px;

      @include media('<widescreen') {
        margin-right: 5px;
      }
    }

    &.--inpatient {
      &:before {
        background-color: #4089F8;
      }
    }

    &.--home {
      &:before {
        background-color: #8EB9F9;
      }
    }

    &.--careTransitions {
      &:before {
        background-color: #2358A8;
      }
    }
    &:hover {
      opacity: 1;
    }
  }
}

.riskList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  color: #414040;
  font-size: 16px;
  font-weight: 700;
  padding-left: 20px;
  padding-bottom: 20px;

  &__item {
    display: flex;
    align-items: flex-start;
    margin-right: 20px;

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      margin-right: 10px;
      margin-top: 3px;
    }

    &.--high {
      &:before {
        background-color: #db4e6f;
      }
    }

    &.--medium {
      &:before {
        background-color: #e5d012;
      }
    }

    &.--low {
      &:before {
        background-color: #6fde47;
      }
    }
  }
  
}

.status {
  display: flex;
  align-items: flex-start;
  white-space: nowrap;

  &:before {
    content: '';
    width: 10px;
    height: 10px;
    margin-right: 10px;
    margin-top: 3px;
    border-radius: 50%;
  }

  &.--sent {
    &:before {
      background-color: #6FDE47;
    }
  }

  &.--inProgress {
    &:before {
      background-color: #E5D012;
    }
  }

  &.--completed {
    &:before {
      background-color: #53A0DF;
    }
  }
}