.b-button {
  border-radius: 5px;
  height: 40px;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  position: relative;

  &.primary {
    background-color: var(--primary-color);
    color: #fff;

    &:hover {
      background-color: #477fb5;
    }
    
    &.darkest {
      background-color: var(--primary-darkest-color);

      &:hover {
        background-color: var(--primary-darkest-hover-color);
      }
    }
  }

  &.transparency {
    background-color: transparent;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    &:hover {
      background-color: var(--primary-color);
      color: #fff
    }
  }

  &.white {
    background-color: white;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    &:hover {
      background-color: var(--primary-color);
      color: #fff
    }
  }

  &.green {
    background-color: #f8fef7;
    border: 1px solid #c6efcb;
    color: #666C72;
    &:hover {
      background-color: #c6efcb;
    }
  }

  &.red {
    background-color: #fff8f8;
    border: 1px solid #fec3c3;
    color: #666c72;
    &:hover {
      background-color: #fec3c3;
    }
  }

  &.-d-flex {
    display: flex;
  }

  &.-ml-10 {
    margin-left: 10px;
  }

  &.-ml-15 {
    margin-left: 15px;
  }

  &.-ml-20 {
    margin-left: 20px;
  }
  
  &.-mx-auto {
    margin: 0 auto;
  }

  &.-mt-8 {
    margin-top: 8px;
  }

  &.-mb-10 {
    margin-bottom: 10px;
  }

  &.-m-5 {
    margin: 5px;
    @media (max-width: 1199px) {
      margin: 5px 0px;
    }
  }

  &.-w-50 {
    min-width: 100px;
    width: 100%;

    @media (max-width: 767) {
      min-width: none;
      width: 50%;
    }
  }

  &.-p-0 {
    padding: 0;
  }
  
  &.-uppercase {
    text-transform: uppercase;
  }

  &.-br-15 {
    border-radius: 15px;
  }
  
  &-small {
    font-size: 14px;
    width: 128px;
  }

  &-large {
    font-size: 16px;
    width: 158px;
  }

  &-disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  &__ic {
    padding-right: 10px;
    display: flex;
  }
}

