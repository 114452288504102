@import "../../../../assets/styles/include-media";

.hccControl {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .hccDelete {
    cursor: pointer;
    margin-right: 17px;
    display: block;
    width: 14px;
    height: 15px;
    background: url("../../../../assets/images/icons/remove.svg") center no-repeat;
  }