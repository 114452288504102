@import "../../../assets/styles/include-media";

.patientControl {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.patientDocLink {
  margin-right: 17px;
  display: block;
  width: 19px;
  height: 20px;
  background: url("../../../assets/images/icons/patient_doc_ic.svg");
  border: none;
  cursor: pointer;
  
}
.patientEditLink {
  margin-right: 17px;
  display: block;
  width: 14px;
  height: 15px;
  background: url("../../../assets/images/icons/patient_edit_ic.svg") center no-repeat;
}

.customTableHeader {
  cursor: pointer;
}

.sendQuestionnaire {
  width: 100%;
  display: flex;

  @include media('<tablet') {
    flex-direction: column;
  }
}

.selectType {
  width: 25%;

  @include media('<tablet') {
    width: 100%;
  }
}

.selectTemplate {
  margin-left: 20px;
  margin-right: 20px;
  width: 60%;

  @include media('<tablet') {
    width: 100%;
    margin: 0;
  }
}

.selectTime {
  width: 15%;

  @include media('<tablet') {
    width: 100%;
  }
}