.react-datepicker__input-container {
  
  &:after {
    position: absolute;
    right: 12px;
    bottom: 12px;
    // z-index: 0;
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: url("../../../assets/images/icons/calendar_ic.svg") center no-repeat;
  }
  
  input {
    width: 100%;
    height: 40px;
    font-size: var(--primary-font-size);
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    background-color: transparent;
    padding-left: 20px;
    padding-right: 20px;
    color: #656565;
  }
}