.b-tooltip {
  font: 600 14px/16px var(--font) !important;
  color: var(--primary-text-color) !important;
  padding: 5px 10px !important;
  border: 1px solid var(--primary-color) !important;
  background: #fff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  max-width: 150px;

  &:after {
    border-top-color: #fff !important;
  }
  &:before {
    border-top-color: var(--primary-color) !important;
   }
}

.b-tooltip__withoutArrow {
  font: 600 14px/16px var(--font) !important;
  color: var(--primary-text-color) !important;
  padding: 5px 10px !important;
  border: 1px solid var(--primary-color) !important;
  background: #fff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  max-width: 150px;
}

.b-tooltipPermission {
  max-width: 150px;
  // font: 400 14px/16px var(--font) !important;
  // color: var(--primary-text-color) !important;
  // padding: 5px 10px !important;
  // border: 1px solid var(--primary-color) !important;
  // background: #fff !important;
  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
}

.b-tooltipCopy {
  position: absolute;
  bottom: 18px;
  font: 600 14px/16px var(--font) !important;
  color: var(--primary-text-color) !important;
  padding: 5px 10px !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 2px;
  background: #fff !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
}