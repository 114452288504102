@import "../../../assets/styles/include-media";

.containerWrapperInner {
  padding: 17px 29px;
}

.controlWrapper {
  display: grid;
  grid-template-columns: 256px 256px 160px 160px;
  grid-gap: 20px;
  
  @media screen and (max-width: 1220px){
    grid-template-columns: 256px 256px;
  }
  @include media('<desktop') {
    grid-template-columns: 200px 200px;
  }
  @include media('<tablet') {
    grid-template-columns: 1fr;
  }
}

.btn {
  margin-top: 24px;

  @media screen and (max-width: 1220px){
    margin-top: 0;
  }
}