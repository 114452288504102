.b-chartBlock {
  &__heading {
    position: absolute;
    top: 22px;
    right: 90px;
    display: flex;
    justify-content: flex-end;
    
    @include media('<desktop') {
      flex-direction: column;
      align-items: flex-start;
      position: initial;
    }
  }
  &__title {
    font-size: 20px;
    font-weight: 900;
    color: var(--primary-text-color);
  }
  &__tabset {
    display: flex;
    font-size: 16px;

    @include media('<desktop') {
      overflow: auto;
      width: 100%;
      margin-bottom: 20px;
    }

    @include media('<tablet') {
      font-size: 14px;
    }

  }
  &__tabsetItem {
    background: none;
    padding: 0 10px 16px;
    position: relative;
    cursor: pointer;
    color: var(--primary-text-color);
    white-space: nowrap;

    @include media('<tablet') {
      padding-left: 5px;
      padding-right: 5px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: var(--primary-color);
      height: 1px;
      display: none;
    }

    &:hover,
    &--active {
      &:after {
        display: block;
      }
    }
  }

  &__content {
    padding: 0 10px;
    display: flex;
    align-items: center;

    @include media('<tablet') {
      flex-direction: column;
      padding: 0;
      align-items: stretch;
    }
  }
  &__chart {
    flex-grow: 1;

    canvas {
      width: 100% !important;
    }
  }
  &__filters {
    width: 190px;
    flex-shrink: 0;

    @include media('<tablet') {
      width: auto;
      margin-top: 20px;
    }
  }
}
