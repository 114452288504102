
.input {
  width: 100%;
  height: 40px;
  font-size: var(--primary-font-size);
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  background-color: transparent;
  padding-left: 20px;
  padding-right: 20px;
  color: #656565;

  &_disable {
    background-color: #F4F4F4;
  }

  &_wrapper {
    position: relative;
  }

  &_error {
    border: 1px solid red;
  }

  &_label {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    color: var(--primary-text-color);
    padding-bottom: 8px;
  }

  &:focus {
    border-color: var(--primary-color);
  }
  
  .refresh {
    padding-right: 40px;
  }
}

.error_text {
  color: red;
  font-size: 12px;
  padding-left: 2px;
  padding-top: 1px;
}

.right_icon {
  position: absolute;
  right: 12px;
  transform: translateY(-50%);
  top: 50%;
  display: flex;
}

.left_icon {
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-right: 1px solid #E5E5E5;
  position: absolute;
  left: 12px;
  transform: translateY(-50%);
  top: 50%
}

.password__show {
  cursor: pointer;
  position: absolute;
  right: 6px;
  transform: translateY(-50%);
  top: 50%;
  padding: 6px;
}

.addSub {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 1px;
  transform: translateY(-50%);
  top: 50%;
  padding: 12px;
  border-right: 1px solid #E5E5E5;
  border-radius: 5px;
  background: #F3F6F9;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
