@import "../../../../assets/styles/include-media";

.patientControl {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.patientDocLink {
  margin-right: 17px;
  display: block;
  width: 19px;
  height: 20px;
  background: url("../../../../assets/images/icons/patient_doc_ic.svg");
  border: none;
  cursor: pointer;
  
}
.patientEditLink {
  margin-right: 17px;
  display: block;
  width: 14px;
  height: 15px;
  background: url("../../../../assets/images/icons/patient_edit_ic.svg") center no-repeat;
}

.customTableHeader {
  cursor: pointer;
}