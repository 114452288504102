.patientControlList {
  padding: 15px 0px;
  display: flex;
  justify-content: flex-start;
}

.searchBlock {
  display: flex;
  max-width: 130px;
  margin-top: 34px;
  margin-bottom: 23px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.searchBlockTitle {
  font: 900 16px/19px var(--font);
  color: var(--title-color);
}

.arrow {
  -webkit-mask: url(../../../../assets/images/icons/pagination_next_ic.svg) no-repeat center;
  mask: url(../../../../assets/images/icons/pagination_next_ic.svg) no-repeat center;
  background-color: var(--primary-color);
  width: 10px;
  display: inline-block;
  transform: rotate(0deg);
  transition: transform .2s;
  margin-left: 10px;
  margin-top: 2px;

  &.arrowOpen {
    transform: rotate(90deg);
  }

}