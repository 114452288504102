.b-accordion {
  
  $block: &;
  
  &__item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &.--opened {
      .b-accordion__arrow {
        transform: rotate(90deg);
      }
      .b-accordion__content {
        height: auto;
      }
    }
  }
  &__opener {
    padding: 10px 20px 10px 30px;
    background-color: transparent;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    transition: all .2s;
    cursor: pointer;

    &:hover {
      background-color: var(--primary-color);
    }
  }
  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--primary-table-text-color);
    margin-right: 15px;
    max-width: calc(100% - 30px);
    transition: .3s;
    
    #{$block}__opener:hover & {
      color: #fff;
    }
  }
  &__arrow {
    -webkit-mask: url(../../../assets/images/icons/pagination_next_ic.svg) no-repeat center;
    mask: url(../../../assets/images/icons/pagination_next_ic.svg) no-repeat center;
    background-color: var(--primary-color);
    width: 10px;
    display: inline-block;
    transform: rotate(0);
    transition: transform .2s;

    #{$block}__opener:hover & {
      background-color: #fff;
    }
  }
  &__content {
    overflow: hidden;
    height: 0;
  }
  &__contentHolder {
    display: flex;
    padding: 40px 10px 20px;

    @include media('<desktop') {
      flex-direction: column;
    }

    p {
      font-size: 14px;
      line-height: 1.8;
    }
  }
  &__contentIllus {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
  }
  &__downloadLink {
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    padding: 10px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 40px;
    border-radius: 5px;
    background-color: var(--primary-color);
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background-color: #477fb5;
    }
  }
}

// zoomBtn
.zoomBtn {
  position: relative;
  font-size: 14px;
  line-height: 30px;
  color: #EDF4FF;
  margin-top: 16px;
  width: 30px;
  height: 30px;
  background: #EDF4FF;
  cursor: pointer;

  &:hover {
    border-radius: 50%;
  }

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    width: 17px;
    height: 17px;
    background: url("../../images/icons/loupe.png") center no-repeat;
    background-size: contain;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
}