.templates {
  &__box {
    display: flex;
    
    @include media('<desktop') {
      flex-direction: column;
    }
  }

  &__leftBox {
    position: relative;
    background: #ffffff;
    margin-right: 15px;
    border-radius: 15px;
    width: 100%;
    max-width: 682px;

    .templates__holder {
      padding: 20px 30px;
    }
  }

  &__rightBox {
    position: relative;

    @include media('<desktop') {
      order: -1;
      margin-bottom: 20px;
      position: sticky;
      top: 30px;
      z-index: 100;
    }
    @include media('<tablet') {
      top: 60px;
    }
  }

  &__questionTypeBox {
    background: #fff;
    width: Min(392px, 100%);
    border-radius: 15px;

    @include media('<desktop') {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
  }

  &__title {
    font-size: 20px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 800;
    line-height: 50px;
    color: #515151;
    padding: 5px 0 5px 30px;
    border-bottom: 1px solid #e5e5e5;
  }

  &__info {
    padding: 30px 0;
    font-size: 16px;
    font-weight: 700;
    color: #155f9b;
  }

  &__infoType {
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 700;
    color: #155f9b;
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 0 30px 30px;
  }

  &__listQuestions {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f0f3f6;
    cursor: move;
  }
  &__listItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #515151;
    padding: 5px;
    height: 40px;
    border: 1px dashed #c4c4c4;
    background-color: #fafafa;
    cursor: move;
    margin-top: 10px;
  }

  &__listItemQuestion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    color: #515151;
    padding: 5px;
    height: 40px;
    border: 1px dashed #0a5899;
    background-color: #fafafa;
    cursor: move;
    margin-top: 10px;

    & p {
      width: 100%;
      margin-bottom: 0px;
    }
    & button {
      cursor: pointer;
      background-color: #fafafa;
    }
  }

  &__listItemQuestionPreview {
    padding: 10px;
    border: 1px dashed #0a5899;
    background-color: #fafafa;
    cursor: move;
    margin-top: 10px;
    position: relative;

    .previewEdit {
      display: flex;
      position: absolute;
      right: 10px;
      top: 15px;
    }
  }

  &__listItemSubQuestionPreview {
    padding: 10px;
    background-color: #F4F7FA;
    position: relative;
    margin-left: 40px;
    border-radius: 5px;
    
    .previewEdit {
      display: flex;
      position: absolute;
      right: 10px;
      top: 15px;
    }
  }

  &__listItemSubQuestionPreview + &__listItemSubQuestionPreview {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 1px solid #53A0DF;
  }

  &__listItemDashed {
    height: 40px;
    cursor: auto;
    margin-top: 10px;
    border: 1px dashed #c4c4c4;
    background-color: #fafafa;
  }

  &__listItemDashedOption {
    height: 40px;
    cursor: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px dashed #c4c4c4;
    background-color: #fafafa;
  }

  &__newList {
    display: flex;
    flex-direction: column;
    padding: 30px;
  }
  &__newList > &__listItem {
    border: 1px dashed #c4c4c4;
  }

  &__categoryBox {
    position: relative;
    padding: 20px 0;
  }
  &__categoryBoxTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #0a5899;
    & button {
      cursor: pointer;
      background-color: #f1f1f1;
    }
  }
  &__categoryList {
    width: 92%;
    background: #ffffff;
    position: absolute;
    left: 30px;
    top: 75px;
    border: 1px solid #dadada;
    padding: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    z-index: 1;
  }

  &__categoryList__item {
    padding: 10px;
    &:hover {
      background-color: #f3f3f3;
      cursor: pointer;
    }
  }

  &__testItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 2px solid red;
    width: 700px;
    height: 700px;
    padding: 10px;
    background-color: black;
    color: white;
    text-align: center;
  }
  &__testItemDR {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 2px solid red;
    width: 200px;
    height: 200px;
    padding: 10px;
    background-color: white;
    color: black;
    text-align: center;
  }

  &__movable-item {
    border-radius: 10px;
    background-color: #fff3f3;
    height: 100px;
    width: 170px;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  }

  &__column {
    width: 200px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__first-column {
    background-color: #f5ffea;
  }

  &__second-column {
    background-color: #fffbf5;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  &__buttonBox {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  &__saveButton {
    width: 100%;
    text-align: center;
    font-weight: 900;
    text-transform: uppercase;
    color: #ffffff;
    background: #53a0df;
    margin: 20px 30px 40px 30px;
    padding: 10px 0px;
    cursor: pointer;
  }

  &__previewButton {
    width: 100%;
    text-align: center;
    font-weight: 900;
    text-transform: uppercase;
    color: #ffffff;
    background: #53a0df;
    margin: 20px 30px 40px 30px;
    padding: 10px 0px;
    cursor: pointer;
  }

  &__backButton {
    text-align: center;
    font-weight: 900;
    text-transform: uppercase;
    color: #ffffff;
    background: #53a0df;
    margin-left: 30px;
    margin-top: 10px;
    padding: 10px;
    cursor: pointer;
  }

}

.fixed {
  display: flex;
  position: sticky;
  top: 30px;
}