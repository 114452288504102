
.select {
  width: 100%;
  height: 40px;
  font-size: var(--primary-font-size);
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  background-color: transparent;
  padding-left: 20px;
  padding-right: 20px;
  color: #656565;
  font-size: 14px;

  &_disable {
    background-color: #F4F4F4;
  }

  &_wrapper {
    position: relative;
    margin-bottom: 16px;
  }

  &_error {
    border: 1px solid red;
    // color: red;
  }

  &_flex {
    display: flex;
    justify-content: space-between;
  }
  
  &_label {
    font-size: 14px;
    line-height: 16px;
    font-weight: 900;
    padding-bottom: 8px;
    color: var(--primary-text-color);
  }

  &:focus {
    border-color: var(--primary-color);
  }
}

.error_text {
  color: red;
  font-size: 12px;
  padding-left: 2px;
}