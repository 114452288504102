@import "../../../assets/styles/include-media";

.headerSearch {
  // padding: 10px 30px 10px 60px;
  padding: 10px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #fff;

  @include media('<tablet') {
    padding: 10px 20px;
  }
  
  &__pageName {
    font: 900 30px/1.2 var(--font);
    color: var(--title-color);
    margin-right: 10px;

    @include media('<tablet') {
      font-size: 23px;
    }
  }
  
  &__searchWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  &__searchIcon {
    margin-left: 20px;

    @include media('<tablet') {
      margin-left: 10px;
    }
  }
  
  &__searchButton {
      font: 400 16px/19px var(--font);
      color: var(--primary-text-color);
      text-align: right;
      padding: 0;
      border: none;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #B2BBC9;

      @include media('<tablet') {
        max-width: 130px;
      }
  }
}

.searchModalBack {
  background-color: transparent !important;
  overflow-y: scroll;
}

.searchModal {
  top: 5% !important;
  left: 97% !important;
  transform: translate(-100%, -5%) !important;
  box-shadow: 0px 4px 90px rgba(0, 0, 0, 0.15) !important;
  border-radius: 15px !important;
}